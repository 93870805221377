// src/components/PurchaseList.js
import React, { useEffect, useState } from 'react';
import { fetchPurchases } from '../services/apiService';
import PurchaseItem from './PurchaseItem';
import { downloadFile, zipAndDownloadFiles, saveToServer } from '../utils/downloadUtils';

const PurchaseList = ({ apiKey }) => {
  const [purchases, setPurchases] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    const loadPurchases = async () => {
      if (apiKey) {
        const data = await fetchPurchases(apiKey);
        setPurchases(data);
      }
    };
    loadPurchases();
  }, [apiKey]);

  const handleDownload = async (item, setProgress) => {
    const files = selectedItems.length ? selectedItems : [item];
    if (files.length > 1) {
      await zipAndDownloadFiles(files);
    } else {
      await downloadFile(item.url, item.name, setProgress);
    }
  };

  const handleSaveToServer = async () => {
    const files = selectedItems;
    await saveToServer(files, (progress) => {
      setPurchases(purchases.map(p => ({ ...p, progress })));
    });
  };

  return (
    <div className="purchase-list">
      {purchases.map(item => (
        <PurchaseItem key={item.id} item={item} onDownload={handleDownload} />
      ))}
      <button onClick={handleSaveToServer}>Save to Server</button>
    </div>
  );
};

export default PurchaseList;
