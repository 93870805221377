// src/utils/downloadUtils.js
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

export const downloadFile = async (url, filename, onProgress) => {
  const response = await fetch(url);
  const blob = await response.blob();
  saveAs(blob, filename);

  if (onProgress) onProgress(100);
};

export const zipAndDownloadFiles = async (files) => {
  const zip = new JSZip();
  const folder = zip.folder('downloads');

  for (const file of files) {
    const response = await fetch(file.url);
    const blob = await response.blob();
    folder.file(file.filename, blob);
  }

  const content = await zip.generateAsync({ type: 'blob' });
  saveAs(content, `downloads_${new Date().toISOString()}.zip`);
};

export const saveToServer = async (files, onProgress) => {
  for (let i = 0; i < files.length; i++) {
    const response = await fetch(files[i].url);
    const blob = await response.blob();
    // Implement server upload logic here
    if (onProgress) onProgress((i + 1) / files.length * 100);
  }
};
