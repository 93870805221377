// src/components/PurchaseItem.js
import React, { useState } from 'react';
import ProgressBar from './ProgressBar';

const PurchaseItem = ({ item, onDownload }) => {
  const [progress, setProgress] = useState(0);

  const handleDownload = async () => {
    await onDownload(item, setProgress);
  };

  return (
    <div className="purchase-item">
      <input type="checkbox" />
      <span>{item.name}</span>
      <button onClick={handleDownload}>Download</button>
      <ProgressBar progress={progress} />
    </div>
  );
};

export default PurchaseItem;
