// src/services/apiService.js
import axios from 'axios';

const API_URL = process.env.REACT_APP_ENVATO_API_URL;

export const fetchPurchases = async (apiKey) => {
  try {
    const response = await axios.get(`${API_URL}/v3/market/buyer/purchases`, {
      headers: {
        Authorization: `Bearer ${apiKey}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching purchases', error);
    throw error;
  }
};
