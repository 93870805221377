// src/App.js
import React, { useState } from 'react';
import PurchaseList from './components/PurchaseList';
import './styles.css';

const App = () => {
  const [apiKey, setApiKey] = useState('');

  const handleApiKeyChange = (e) => {
    setApiKey(e.target.value);
  };

  return (
    <div className="App">
      <h1>Envato Purchases</h1>
      <input
        type="text"
        placeholder="Enter your API key"
        value={apiKey}
        onChange={handleApiKeyChange}
      />
      <PurchaseList apiKey={apiKey} />
    </div>
  );
};

export default App;
